import React, { useEffect } from 'react';

function NavBar() {
    useEffect(() => {
        // Check if the current URL is the home ("/") URL
        const isHome = window.location.pathname === '/';
    
        // If it is the home page, add the 'fixed-navbar' class
        if (isHome) {
          document.getElementById('navbar').classList.add('fixed');
        } else {
          // If it's not the home page, remove the 'fixed-navbar' class
          document.getElementById('navbar').classList.remove('fixed');
        }
      }, []); // Empty dependency array to run the effect only once on mount
  
    return (
        <div>
            <nav id="navbar" className="dark:bg-black w-full z-20 top-0 start-0 border-b">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            {/* logo click goes to home */}
            <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                {/* <img src="https://flowbite.com/docs/images/logo.svg" className="h-8" alt="Flowbite Logo"/> */}
                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                    <path d="M12.356 5.435 1.938 16.384a.5.5 0 0 0 .018.707l2.9 2.757a.5.5 0 0 0 .706-.018L15.978 8.882l-3.622-3.447Zm7.681-.819a.5.5 0 0 0-.018-.706l-2.9-2.757a.5.5 0 0 0-.707.017l-2.68 2.817 3.622 3.446 2.683-2.817Zm-2.89 12.233-1 .025-.024-1a1 1 0 1 0-2 .05l.025 1-1 .024a1 1 0 0 0 .05 2l1-.025.025 1a1 1 0 1 0 2-.05l-.025-1 1-.024a1 1 0 1 0-.05-2h-.001ZM2.953 9.2l.025 1a1 1 0 1 0 2-.05l-.025-1 1-.025a1 1 0 1 0-.05-2l-1 .025-.025-1a1 1 0 0 0-2 .049l.025 1-1 .025a1 1 0 0 0 .05 2l1-.024Zm15.07 2.626-2 .05.05 1.999 2-.05-.05-1.999ZM11.752.978l-2 .05.05 2 2-.05-.05-2Zm-2.95 2.075-2 .05.05 1.999 2-.05-.05-2ZM5.753 1.127l-1.999.05.05 2 1.999-.05-.05-2Zm15.194 7.625-2 .05.05 2 2-.05-.05-2Zm.125 4.998-2 .05.05 2 2-.05-.05-2Z"/>
                </svg>
                <span className="self-center text-2xl font-semibold whitespace-nowrap text-transparent bg-clip-text bg-gradient-to-r to-rose-600 from-lime-400">WOS PROJECT</span>
            </a>
            <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <a href="/LearnMore" className="py-2 px-3 dark:bg-black">
                <button type="button" className="text-white dark:bg-black hover:red focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-red-600 dark:hover:bg-red-900 dark:focus:ring-blue-800">
                    Learn More</button></a>
                
                <button data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button>
            </div>

            <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
                <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 dark:bg-black-800 md:dark:bg-black-900 dark:border-black-700">
                <li>
                    <a href="/" className={`block py-2 px-3 rounded  md:p-0 ${window.location.pathname === '/' ? 'text-red-500' : 'dark:text-white'}`} aria-current="page">Home</a>
                </li>
                <li>
                    <a href="/wosDB" className={`block py-2 px-3 rounded  md:p-0 ${window.location.pathname === '/wosDB' ? 'text-red-500' : 'dark:text-white'}`}>wosDB</a>
                </li>
                <li>
                    <a href="/wosCV" className={`block py-2 px-3 rounded  md:p-0 ${window.location.pathname === '/wosCV' ? 'text-red-500' : 'dark:text-white'}`}>wosCV</a>
                </li>
                <li>
                    <a href="/About" className={`block py-2 px-3 rounded  md:p-0 ${window.location.pathname === '/About' ? 'text-red-500' : 'dark:text-white'}`}>About</a>
                </li>
                <li>
                    <a href="/View" className={`block py-2 px-3 rounded  md:p-0 ${window.location.pathname === '/View' ? 'text-red-500' : 'dark:text-white'}`}>View</a>
                </li>
                </ul>
            </div>
            </div>
            </nav>

      </div>
    );
  }


  export default NavBar;
