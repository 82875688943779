import React, { useState } from 'react';

const ViewPage = () => {
  const [videoUrl, setVideoUrl] = useState("");

  const handleInputChange = (event) => {
    setVideoUrl(event.target.value);
  };

  return (
    <div className="dark:bg-black text-white min-h-screen flex flex-col items-center justify-center">
      <h1 className="text-4xl font-bold mb-8">Input a Video URL Below</h1>
      <input
        type="text"
        value={videoUrl}
        onChange={handleInputChange}
        placeholder="Enter a video URL..."
        className="w-3/4 p-3 text-black rounded mb-8"
      />
      {videoUrl && (
        <div className="video-container w-full h-screen">
          <video
            controls
            className="w-full h-full object-cover"
            src={videoUrl}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </div>
  );
};

export default ViewPage;
